import React, { useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { ChatText } from "@phosphor-icons/react";
import classes from "./SendTokenButton.module.css";
import { useHistory } from "react-router-dom";
import { IconButton } from "../../../../../node_modules/@mui/material/index";
import { trackEvent } from "utils/event_tracking";

const SendMessageButton = ({ identity, onlyIcon = false }) => {
  const { identityDetails } = useContext(AuthContext);
  const history = useHistory();
  const isLoggedInUserProfile =
    identity?.id === identityDetails.current?.identity?.id;
  const isVerified =
    identity?.is_user_account ||
    identity?.user_since != null ||
    identity?.is_verified;
  if (isLoggedInUserProfile || !isVerified) return null;

  const gotoChat = () => {
    trackEvent("message_button");
    history.push(`/messages/all/${identity?.id}`);
  };

  return onlyIcon ? (
    <IconButton
      sx={{
        "&.MuiIconButton-root": {
          background: "var(--elevation-2)",
        },
      }}
      onClick={gotoChat}>
      <ChatText size="16" color="var(--text-1)" />
    </IconButton>
  ) : (
    <button className={`${classes.connectButton}`} onClick={gotoChat}>
      <ChatText size="16" weight="bold" />
      <span>Message</span>
    </button>
  );
};

export default SendMessageButton;

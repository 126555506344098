import { cutStringBeyondLength } from "./misc";

export const getLinkForWallet = (address) => {
  const url = window.location.pathname;
  const preWallets = url.split("/wallets")[0];
  const link = `${preWallets}/wallets/${address}/` + window.location.search;
  return link;
};

export const getLinkForWalletTab = () => {
  const url = window.location.pathname;
  const preWallets = url.split("/wallets")[0];
  return `${preWallets}/wallets` + window.location.search;
};

export const getLinkForWalletsBundle = ({ address, identityPerspective }) => {
  const url = window.location.pathname;
  const preWallets = url.split("/wallets")[0];
  const link = `${preWallets}/wallets/${address}/${identityPerspective}`;
  return link;
};

export const getNetworth = (profile, address, chain = "_Any") => {
  if (!profile) return null;

  if (!address) {
    if (profile.net_worth == null) return null;
    return profile.net_worth[chain]?.total_usd_value?.display_value;
  }
  const addressProfile = profile?.profiles[address];
  if (addressProfile?.net_worth == null) return null;
  return addressProfile?.net_worth[chain]?.total_usd_value?.display_value;
};

function getOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const getFormattedMonthNameDate = ({
  date,
  monthFormat = "long",
  yearFormat = "short",
  showTime = false,
}) => {
  try {
    if (!date) return null;
    const dateObject = new Date(date);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: monthFormat });
    const fullYear = dateObject.getFullYear();
    const year =
      yearFormat !== "short" ? fullYear.toString().slice(-2) : fullYear;

    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    if (!showTime) return formattedDate;
    let hours = dateObject.getHours();
    const am_pm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const minutes = dateObject.getMinutes();
    const formattedTime = `${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${am_pm}`;

    const formattedDateTime = `${day}${getOrdinalSuffix(
      day
    )} ${month}, ${year} ${formattedTime}`;
    return formattedDateTime;
  } catch (e) {
    return null;
  }
};

export const getShortDotFormatAddress = ({ address, numbersIncluding = 4 }) => {
  if (!address) return null;
  const prefixLength = numbersIncluding + 2;
  const suffixLength = numbersIncluding;
  const dotsCount = 3;

  return cutStringBeyondLength({
    text: address,
    length: prefixLength + suffixLength + dotsCount,
    dotsCount,
    suffixLength,
  });
};

//function to get if token is included in the selected tokens
export const isTokenIncludedForSelectedTokens = ({
  selectedActiveTokens,
  selectedActiveProtocols,
  holding,
}) => {
  var flag = false;
  selectedActiveTokens.forEach((token) => {
    if (
      holding?.token_metadata?.symbol &&
      holding?.chain_id &&
      holding?.token_name &&
      holding?.token_metadata?.symbol === token?.token_metadata?.symbol &&
      holding?.chain_id === token?.chain_id &&
      holding?.token_name === token?.token_name
    ) {
      flag = true;
      return;
    }
  });
  //no need to check in protocols if already found in tokens
  if (flag) return true;
  selectedActiveProtocols.forEach((protocol) => {
    if (
      holding?.chain_id === protocol?.chain_id &&
      holding?.contract_address === protocol?.contract_address
    ) {
      flag = true;
      return;
    }
  });
  return flag;
};

//function to get if token is included in the selected tokens
export const isTokenIncludedForSelectedProtocols = ({
  selectedActiveProtocols,
  selectedActiveTokens,
  holding,
}) => {
  var flag = false;
  selectedActiveProtocols.forEach((protocol) => {
    if (
      holding?.chain_id === protocol?.chain_id &&
      holding?.tokens?.includes(protocol.contract_address)
    ) {
      flag = true;
      return;
    }
  });
  //no need to check in tokens if already found in protocols
  if (flag) return true;
  selectedActiveTokens.forEach((token) => {
    if (
      holding?.chain_id === token?.chain_id &&
      holding?.tokens?.includes(token.contract_address)
    ) {
      flag = true;
      return;
    }
  });
  return flag;
};

export const getHoldingsAfterSearch = ({ tokenHoldingsData, searchTerm }) => {
  if (!searchTerm) return tokenHoldingsData.holdings;
  const filteredHoldings = tokenHoldingsData?.holdings.filter((holding) => {
    if (
      (holding?.token_name &&
        holding?.token_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (holding?.contract_address && holding?.contract_address === searchTerm) ||
      (holding?.token_metadata?.symbol &&
        holding?.token_metadata?.symbol
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()))
    ) {
      return true;
    }
    return false;
  });
  return filteredHoldings;
};

export const getProtocolHoldingsAfterSearch = ({
  protocolHoldings,
  protocolsMetadata,
  searchTerm,
}) => {
  if (!searchTerm) return protocolHoldings;
  const filteredHoldings = protocolHoldings?.filter((protocol) => {
    const metadata = protocolsMetadata[protocol?.contract_address];
    if (
      metadata?.name &&
      metadata?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    return false;
  });
  return filteredHoldings;
};

export const getTokensAfterSearch = ({
  tokensList,
  searchTerm,
  keys = ["name", "symbol"],
}) => {
  if (!searchTerm) return tokensList;

  const searchLowercase = searchTerm.toLowerCase();
  const tokensSearchedList = tokensList?.filter((token) => {
    for (const key of keys) {
      if (
        token?.[key] &&
        token?.[key].toLowerCase().includes(searchLowercase)
      ) {
        return true;
      }
    }

    return false;
  });
  return tokensSearchedList;
};

/// tokens which are already in token holdings will be removed from the list of tokens
export const getProtcolHoldingsTokenAddressList = ({
  protocolHoldings,
  tokenHoldings,
}) => {
  const uniqueTokensSet = getTokenHoldingsAddressSet({ tokenHoldings });
  const uniqueProtcolTokensSet = getProtocolUniqueTokensSet({
    protocolHoldings,
  });
  const protocolUniqueTokensArray = [...uniqueProtcolTokensSet].filter(
    (protocolToken) => {
      const existsInTokenHoldings = [...uniqueTokensSet].some((token) => {
        const { contract_address, chain_id } = token;
        return (
          protocolToken.contract_address === contract_address &&
          protocolToken.chain_id === chain_id
        );
      });
      return !existsInTokenHoldings;
    }
  );
  return protocolUniqueTokensArray;
};

export const getTokenHoldingsAddressSet = ({ tokenHoldings }) => {
  const tokenHoldingsAddressSet = new Set();
  tokenHoldings?.holdings?.forEach((holding) => {
    tokenHoldingsAddressSet.add({
      contract_address: holding?.contract_address,
      chain_id: holding?.chain_id,
    });
  });
  return tokenHoldingsAddressSet;
};

export const getProtocolUniqueTokensSet = ({ protocolHoldings }) => {
  const protocolUniqueTokensSet = new Set();
  protocolHoldings?.holdings?.forEach((protocol) => {
    protocol?.tokens?.forEach((token) => {
      protocolUniqueTokensSet.add({
        contract_address: token,
        chain_id: protocol?.chain_id,
      });
    });
  });
  return protocolUniqueTokensSet;
};

export const getTokensAndProtocolList = ({
  selectedActiveTokens,
  selectedActiveProtocols,
}) => {
  const tokensList = [];
  selectedActiveTokens?.forEach((holding) => {
    tokensList.push({
      contract_address: holding?.contract_address,
      chain_id: holding?.chain_id,
    });
  });
  selectedActiveProtocols?.forEach((protocol) => {
    tokensList.push({
      contract_address: protocol?.contract_address,
      chain_id: protocol?.chain_id,
    });
  });
  return tokensList;
};

export const getHoldingsWithoutNativeTokens = (draft) => {
  const filteredHoldings = draft.holdings.filter((holding) => {
    if (
      holding?.contract_address &&
      !(holding?.token_link?.includes("native_token") ?? true)
    ) {
      return true;
    }
    return false;
  });
  draft.holdings = filteredHoldings;
};

export const getDisplayNameForProfile = (profile) => {
  if (profile?.is_token) {
    return profile?.token_details?.symbol ?? profile.display_name;
  }
  return profile?.display_name;
};

export const getDisplayPictureForProfile = (profile) => {
  if (profile?.is_token) {
    return profile?.token_details?.logo ?? profile?.display_picture;
  }
  return profile?.display_picture;
};

export const reasonsFromProfile = (profile) => {
  const reasons = [];
  if (profile.followed_on_twitter) {
    reasons.push({
      type: "twitter",
    });
  }
  if (profile.follow_of_follows != null) {
    reasons.push({
      type: "follow_of_followed",
      follower: profile.follow_of_follows,
    });
  }
  return { reasons };
};

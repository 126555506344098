import React from "react";
import { Link } from "react-router-dom";
import classes from "./ConditionalLink.module.css";

/**
 * returns a link if to/to.pathname not null
 * if to.pathname supplied open in a new tab
 * @param {*} props
 * @returns
 */
const ConditionalLink = React.forwardRef(
  ({ to, children, sameTab = true, onClick, className, ...props }, ref) => {
    return to?.length || to?.pathname?.length ? (
      <Link
        ref={ref}
        to={to}
        onClick={(e) => {
          onClick?.(e);
          e.stopPropagation();
        }}
        className={className ?? classes.overridableClass}
        {...props}
        {...(!sameTab ? { target: "_blank", rel: "noopener noreferrer" } : {})}>
        {children}
      </Link>
    ) : (
      <div ref={ref} className={className} {...props}>
        {children}
      </div>
    );
  }
);

export default ConditionalLink;

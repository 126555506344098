import { handleTwitterHandles, handleUrls, stripTags } from "utils/misc";
import classes from "./ProfileInfo.module.css";
const Bio = ({ bio, className, clampBio = false, alignLeft = false }) => {
  bio = stripTags(bio);
  bio = handleTwitterHandles(bio);
  bio = handleUrls(bio);
  return (
    <div
      className={`${className ?? classes.bio} ${
        clampBio ? classes.clampBio : ""
      } ${alignLeft ? classes.alignLeft : ""}`}
      dangerouslySetInnerHTML={{ __html: bio }}
    />
  );
};

export default Bio;

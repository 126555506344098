import classes from "./ProfileHoverCard.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  FlexGrow,
  Divider,
  BulletPoint,
  RedirectLink,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import profileInfoClasses from "../ProfileHeader/ProfileInfo.module.css";
import { Link } from "react-router-dom";
import { getTrimedAddress } from "utils/misc";
import { getNetworth } from "utils/profile_utils";
import Bio from "../ProfileHeader/Bio";
import ExternalLinks from "../ProfileHeader/ExternalLinks";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import useProfileDetails from "../ProfileHeader/useProfileDetails";
import bundleIcon from "assets/svg/bundle.svg";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { IconTextButton } from "components/UI/Components/IconTextButton";
import BundleButton from "./components/BundleButton";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import ProtocolHoverCard from "components/V2/Protocol/ProtocolHover/ProtocolHoverCard";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import Copyable from "components/ValueType/Copyable/Copyable";
import { useEffect, useState } from "react";
import { LoadingHoverCard } from "components/UI/Components/Components";
import SendMessageButton from "../ProfileHeader/SendMessageButton";

const ProfileHoverCard = ({
  actor: originalActor,
  addressPairWiseSummary,
  addressPairWiseSummaryData = {},
  onActorUpdate,
  children,
}) => {
  const [actor, setActor] = useState(originalActor);
  const [queryProfileFromAddress, setQueryProfileFromAddress] = useState(false);
  const {
    data: profileData,
    isLoading,
    refetch,
  } = useProfileDetails({
    identifier: queryProfileFromAddress
      ? actor?.identity_id ?? actor?.address
      : null,
  });
  useEffect(() => {
    if (originalActor?.profiles_dict && profileData) {
      setActor({
        ...originalActor,
        ...profileData?.data?.data?.details,
      });
    } else {
      setActor(originalActor);
    }
  }, [profileData, originalActor]);

  if (actor == null) {
    return children;
  }

  const isUserProfile = actor.is_user_profile;

  if (!isUserProfile) {
    if (actor.address_type === "Contract") {
      return (
        <ProtocolHoverCard
          protocolData={actor?.protocol_details}
          contractData={actor}>
          {children}
        </ProtocolHoverCard>
      );
    } else if (actor.is_token) {
      return <TokenHoverCard tokenProfile={actor}>{children}</TokenHoverCard>;
    } else if (actor.address_type === "NFT") {
      return (
        <TokenHoverCard tokenProfile={actor} isNft={true}>
          {children}
        </TokenHoverCard>
      );
    }
  }
  return (
    <PPLxHoverCard
      onOpen={() => {
        setQueryProfileFromAddress(true);
      }}
      key={actor?.identity_id || actor?.address}
      content={
        isLoading && originalActor?.profiles_dict ? (
          <LoadingHoverCard />
        ) : (
          <ProfileHoverCardContent
            actor={actor}
            addressPairWiseSummary={addressPairWiseSummary}
            addressPairWiseSummaryData={addressPairWiseSummaryData}
            onActorUpdate={() => {
              refetch();
              onActorUpdate?.();
            }}
          />
        )
      }>
      {children}
    </PPLxHoverCard>
  );
};

const ProfileHoverCardContent = ({
  actor,
  addressPairWiseSummary,
  addressPairWiseSummaryData = {},
  onActorUpdate,
}) => {
  const { data, isSuccess } = useProfileDetails({
    identifier: actor?.identity_id || actor?.id,
  });
  // Hovercards are always identity-first
  let profileIdentityData = actor;
  if (isSuccess) {
    profileIdentityData = data?.data?.data?.details;
  }
  const networth = getNetworth(profileIdentityData) ?? getNetworth(actor);
  const profileLink =
    profileIdentityData?.identity_link || profileIdentityData?.link;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.container}>
      <CustomColumn>
        <CustomRow alignItems="center">
          <RedirectLink link={profileLink}>
            {profileIdentityData?.display_picture == null ? (
              <div className={classes.addressImage}></div>
            ) : (
              <Image
                src={profileIdentityData?.display_picture}
                className={classes.profilePic}
                alt={
                  profileIdentityData?.display_name ||
                  profileIdentityData?.title ||
                  profileIdentityData?.address
                }
              />
            )}
          </RedirectLink>
          <FlexGrow>
            <CustomRow alignItems="center" justifyContent="flex-end" gap="8px">
              {(profileIdentityData?.is_user_account ||
                profileIdentityData?.user_since != null ||
                profileIdentityData?.is_verified) && (
                <SendMessageButton
                  identity={profileIdentityData}
                  onlyIcon={true}
                />
              )}
              <div className={classes.follow_panel}>
                <FollowEditButton
                  showEdit={false}
                  name={profileIdentityData?.display_name}
                  twitter={profileIdentityData?.socials?.twitter}
                  socials={profileIdentityData?.socials}
                  bio={profileIdentityData?.bio}
                  followed={profileIdentityData?.followed}
                  address={profileIdentityData?.address}
                  identityId={
                    profileIdentityData?.identity_id || profileIdentityData?.id
                  }
                  profileType={profileIdentityData?.profile_type}
                  onUpdateCallback={onActorUpdate}
                  disabled={profileIdentityData.disable_follow}
                  followDirectly={profileIdentityData.follow_directly}
                />
              </div>
            </CustomRow>
          </FlexGrow>
        </CustomRow>
        <PaddingComponent height="12px" />
        <RedirectLink link={profileLink}>
          <span className={classes.title}>
            <span>
              {profileIdentityData?.display_name ||
                profileIdentityData?.title ||
                profileIdentityData?.address}
            </span>
            <VerifiedBadge profile={profileIdentityData} showTooltip />
            {profileIdentityData?.address_type === "External" &&
              profileIdentityData?.address && (
                <Copyable content={profileIdentityData?.address} />
              )}
          </span>
        </RedirectLink>
        {profileIdentityData && (
          <div className={classes.wrapDiv}>
            {profileIdentityData.username?.length > 0 && (
              <span className={profileInfoClasses.aliases}>
                {"@"}
                {profileIdentityData.username}
              </span>
            )}
            {Object.keys(profileIdentityData.aliases ?? {}).length > 0 &&
              profileIdentityData.username?.length > 0 && <BulletPoint />}
            {Object.keys(profileIdentityData.aliases ?? {})
              .slice(0, 3)
              .map((alias, index) => {
                const isLast =
                  index ===
                  Object.keys(profileIdentityData.aliases ?? {}).slice(0, 3)
                    .length -
                    1;
                return (
                  <PaddingComponent
                    paddingRight={isLast ? "0px" : "4px"}
                    key={index}>
                    <span className={profileInfoClasses.aliases}>
                      {alias}
                      {!isLast && ", "}
                    </span>
                  </PaddingComponent>
                );
              })}
          </div>
        )}
        <PaddingComponent height="10px" />
        {profileIdentityData?.external_links && (
          <ExternalLinks links={profileIdentityData.external_links} />
        )}
        {(networth ||
          profileIdentityData?.bundle?.address_infos?.length > 1) && (
          <CustomColumn>
            <PaddingComponent height="12px" />
            <div className={classes.networth_bundle_row}>
              <div className={profileInfoClasses.active_since_left}>
                <CustomRow>
                  <span className={classes.networth}>{networth}</span>

                  <CustomRow>
                    {networth &&
                      profileIdentityData?.bundle?.address_infos?.length >
                        0 && (
                        <PaddingComponent paddingLeft="6px" paddingRight="6px">
                          <span className={classes.networth}>{` · `}</span>
                        </PaddingComponent>
                      )}
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      to={profileLink}>
                      <div className={profileInfoClasses.bundle_cta}>
                        <span className={classes.networth}>
                          {getTrimedAddress(
                            profileIdentityData?.bundle?.address_infos?.[0]
                              ?.address
                          )}
                        </span>
                        {profileIdentityData?.bundle?.address_infos && (
                          <Copyable
                            content={
                              profileIdentityData?.bundle?.address_infos[0]
                                ?.address
                            }
                          />
                        )}
                        {profileIdentityData?.bundle?.address_infos?.length >
                        1 ? (
                          <span
                            className={`${profileInfoClasses.padding_left} ${profileInfoClasses.aliases}`}>
                            {"+"}
                            {profileIdentityData?.bundle?.address_infos
                              ?.length - 1}{" "}
                            {profileIdentityData?.bundle?.address_infos
                              ?.length -
                              1 >
                            1
                              ? "Wallets"
                              : "Wallet"}
                          </span>
                        ) : null}
                      </div>
                    </Link>
                  </CustomRow>
                </CustomRow>
              </div>
            </div>
          </CustomColumn>
        )}
        {profileIdentityData?.bio && (
          <PaddingComponent paddingTop="12px">
            <Bio className={classes.bio} bio={profileIdentityData?.bio} />
          </PaddingComponent>
        )}
        <PaddingComponent height="12px" />
        {!!(actor?.identity_id || actor?.id) && (
          <CustomRow>
            <span className={profileInfoClasses.count}>
              {profileIdentityData?.following_count?.value}
            </span>
            <PaddingComponent width="4px" />
            <span className={profileInfoClasses.text}>following</span>
            <PaddingComponent width="16px" />
            <span className={profileInfoClasses.count}>
              {profileIdentityData.follower_count?.value}{" "}
            </span>
            <PaddingComponent width="4px" />
            <span className={profileInfoClasses.text}>followers</span>
          </CustomRow>
        )}

        {addressPairWiseSummary?.relationship_summary?.length > 0 && (
          <CustomColumn>
            <PaddingComponent height="12px" />
            <Divider />
            <PaddingComponent height="12px" />
            <CustomRow>
              <img
                src={bundleIcon}
                className={classes.bundleIcon}
                alt="bundle-icon"></img>
              <PaddingComponent width="12px" />
              <CustomColumn>
                {addressPairWiseSummary?.relationship_summary?.map(
                  (text, idx) => {
                    return (
                      <FeedDescription
                        key={idx}
                        fontSize="13px"
                        fontColor="var(--text-2)"
                        templateString={text}
                        profiles={addressPairWiseSummaryData.profiles}
                        actor={addressPairWiseSummaryData.actor}
                        nativeTokensInfo={
                          addressPairWiseSummaryData.nativeTokensInfo
                        }
                        methodIds={addressPairWiseSummaryData.methodIds}
                      />
                    );
                  }
                )}
                <PaddingComponent height="8px" />
                <CustomRow>
                  <RedirectLink
                    link={`/explorer?identifier=${
                      addressPairWiseSummary?.related_with_identity_id ||
                      addressPairWiseSummary?.related_with
                    },${profileIdentityData?.address}`}>
                    <IconTextButton text="View Transfers" onClick={() => {}} />
                  </RedirectLink>
                  <PaddingComponent width="20px" />
                  {addressPairWiseSummary?.confidence &&
                    addressPairWiseSummary?.are_related &&
                    addressPairWiseSummaryData?.actor?.identity_id != null && (
                      <BundleButton
                        identityId={
                          addressPairWiseSummaryData?.actor?.identity_id
                        }
                        address={profileIdentityData?.address}
                      />
                    )}
                </CustomRow>
              </CustomColumn>
            </CustomRow>
          </CustomColumn>
        )}
      </CustomColumn>
    </div>
  );
};

export default ProfileHoverCard;

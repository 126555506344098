import React from "react";
import { Link, useParams } from "react-router-dom";
import ConditionalLink from "shared/ConditionalLink";
import styled from "styled-components/macro";
import classes from "./decriptionBox.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { getImageNameAndType } from "components/UI/Components/UserImagesList";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";

export const ProfileImagesWrapper = styled.div`
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  position: relative;
  ${(props) => `
    width: ${props.width};
  `}
`;

const BoldSpanText = styled.span`
  font-weight: 500;
`;

const PreWrapText = styled.span`
  white-space: pre-wrap;
  font-weight: 500;
`;

const LinkWrapper = ({ children, link }) => {
  return link ? (
    <Link style={{ textDecoration: "none" }} to={link}>
      {children}
    </Link>
  ) : (
    children
  );
};

const ProfileMutualFollowers = ({
  identifiersList,
  personsLength = 0,
  profiles,
  identities,
  nativeTokensInfo,
  isToken = false,
  isContract = false,
  addressPairWiseSummary,
  refetchGroupedActivity,
}) => {
  let { identifier, active_tab } = useParams();
  let list = [];
  const parsedPersonsLength = parseInt(personsLength);
  let extraLength = parsedPersonsLength ?? 0;

  if (!Array.isArray(identifiersList)) {
    list = identifiersList.split(",");
  } else {
    list = identifiersList;
  }

  if (list.length > 3) {
    extraLength += list.length - 3;
    list = list.slice(0, 3);
  }

  list = list.map((address) => address.trim());

  const convertToObjectArray = (obj) => {
    const result = [];
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        result.push(obj[key]);
      }
    }
    return result;
  };

  const getIdentitiesInOrder = (list, identities) => {
    return list.map((id) => identities[id]);
  };

  const mutualProfilesForImages = getIdentitiesInOrder(list, identities)
    ? convertToObjectArray(getIdentitiesInOrder(list, identities))
    : [];
  return (
    <>
      {mutualProfilesForImages.length > 0 && (
        <PaddingComponent>
          <CustomColumn
            style={{
              width:
                mutualProfilesForImages.length === 1
                  ? "33px"
                  : `${mutualProfilesForImages.length * 26}px`,
            }}
            justifyContent="center">
            {mutualProfilesForImages?.map((profile, index) => {
              return (
                <ProfileHoverCard
                  key={`avatar-${profile?.display_name}-${index}`}
                  actor={profile}
                  onActorUpdate={() => {}}>
                  <div
                    className={`${
                      index === 0
                        ? classes.profileInitial
                        : classes.profileSeries
                    }`}
                    style={{
                      zIndex: index + 1,
                      marginLeft:
                        index === 0
                          ? "0px"
                          : `${(index === 1 ? 21 : 22) * index}px`,
                      position: index === 0 ? "relative" : "absolute",
                    }}>
                    <Image
                      imageType={IMAGE_TYPES.AVATAR}
                      alt={`profile-${index}`}
                      src={profile?.display_picture}
                    />
                  </div>
                </ProfileHoverCard>
              );
            })}
          </CustomColumn>
        </PaddingComponent>
      )}
      {list.map((address, index) => {
        const person = profiles ? profiles[address] : identities[address];
        const token =
          nativeTokensInfo && person == null ? nativeTokensInfo[address] : null;
        const { name } = getImageNameAndType({
          token,
          isToken,
          isContract,
          person,
        });
        let displayName = name;
        if (displayName?.length > 23) {
          displayName = `${displayName.slice(0, 20)}...`;
        }

        return (
          <React.Fragment
            key={`multi-profiles-list-${index}-${identifiersList}`}>
            <ProfileHoverCard
              actor={person}
              addressPairWiseSummaryData={{
                profiles,
                person,
                nativeTokensInfo,
              }}
              onActorUpdate={refetchGroupedActivity}
              refetchGroupedActivity={refetchGroupedActivity}
              addressPairWiseSummary={
                addressPairWiseSummary
                  ? addressPairWiseSummary[person?.address]
                  : null
              }>
              <ConditionalLink
                to={person?.link}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  color: "inherit",
                  cursor: "pointer",
                }}>
                <CustomRow alignItems="center" gap="4px">
                  <BoldSpanText>{displayName}</BoldSpanText>
                </CustomRow>
              </ConditionalLink>
            </ProfileHoverCard>
            {index !== list.length - 1 && <PreWrapText>{", "}</PreWrapText>}
          </React.Fragment>
        );
      })}
      {extraLength > 0 && (
        <LinkWrapper link={`/${identifier}/${active_tab}/mutual`}>
          <PreWrapText>
            {" +"}
            {extraLength}
            {" more"}
          </PreWrapText>
        </LinkWrapper>
      )}
    </>
  );
};

export default ProfileMutualFollowers;

import { useMemo } from "react";
import { produce } from "immer";
import { Chart, PRICE_DELTA_DURATIONS } from "utils/constants";
import { tsCutoff } from "./chartUtils";
import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "api/api";

export const useFetchPriceChartData = (
  tokenSlug,
  geckoTerminalKey,
  days,
  isLineChart,
  chainId,
  tokenAddress
) => {
  const daysForChart = days === Chart.DURATION_DAYS["ALL"] ? "max" : days;
  const chartType = isLineChart ? "market_chart" : "ohlc";
  const geckoTerminalDataAvailable = geckoTerminalKey?.every((k) => k != null);
  const path = useMemo(
    () =>
      tokenSlug
        ? `https://api.coingecko.com/api/v3/coins/${tokenSlug}/${chartType}?vs_currency=usd&days=${daysForChart}`
        : geckoTerminalDataAvailable
          ? getGeckoTerminalUrl(...geckoTerminalKey, daysForChart)
          : null,
    [
      tokenSlug,
      chartType,
      daysForChart,
      geckoTerminalKey,
      geckoTerminalDataAvailable,
    ]
  );

  const fetchPriceChartData = async () => {
    const resp = await makeApiRequest({
      path,
      tokenSlug,
      daysForChart,
      isLineChart,
      geckoTerminalKey,
      chainId,
      tokenAddress,
      geckoTerminalDataAvailable,
    });
    if (
      (geckoTerminalDataAvailable || !isLineChart) &&
      !resp?.fallbackResponseFromGeckoTerminal
    ) {
      return resp;
    } else {
      return filterCoinGeckoDataForChart(resp, days);
    }
  };

  const enabled =
    tokenSlug || (chainId && tokenAddress) || geckoTerminalDataAvailable;

  const priceChartQuery = useQuery({
    queryKey: [
      "get-token-price-chart",
      tokenSlug,
      ...geckoTerminalKey,
      days,
      isLineChart,
    ],
    queryFn: fetchPriceChartData,
    refetchOnWindowFocus: false,
    retry: 2,
    retryOnMount: false,
    enabled: !!enabled,
    staleTime: 100,
  });

  return priceChartQuery;
};

const getGeckoTerminalParams = (days) => {
  let rangeType, aggregate, limit;
  switch (days) {
    default:
    case "max":
      rangeType = "day";
      aggregate = 1;
      limit = 1000;
      break;
    case 1:
      rangeType = "minute";
      aggregate = 5;
      limit = 720;
      break;
    case 7:
      rangeType = "hour";
      aggregate = 1;
      limit = 24 * 7;
      break;
    case 30:
      rangeType = "hour";
      aggregate = 1;
      limit = 24 * 30;
      break;
    case 90:
      rangeType = "hour";
      aggregate = 4;
      limit = (24 * 90) / 4;
      break;
    case 180:
      rangeType = "hour";
      aggregate = 12;
      limit = (24 * 180) / 12;
      break;
    case 365:
      rangeType = "day";
      aggregate = 1;
      limit = 365;
  }
  return {
    rangeType,
    aggregate,
    limit,
  };
};

const makeApiRequest = async ({
  path,
  tokenSlug,
  daysForChart,
  isLineChart,
  geckoTerminalKey,
  chainId,
  tokenAddress,
  geckoTerminalDataAvailable,
}) => {
  try {
    // add for testing gecko terminal fallback
    // if (!tokenSlug) {
    //   throw new Error("Token slug not found");
    // }
    if (!path) throw new Error("Path not found, trying fallbacks");
    let resp = await fetch(path);
    if (resp.status !== 200) {
      throw new Error("Too many requests");
    }
    resp = await resp?.json();
    if (!tokenSlug) {
      return isLineChart
        ? transformGeckoTerminalForChart(resp, daysForChart)
        : transformGeckoTerminalForOHLC(resp);
    }
    return resp;
  } catch (e) {
    if (tokenSlug || !geckoTerminalDataAvailable) {
      const backendResp = await getCoingeckoStyleGraphPriceForTokens({
        slug: tokenSlug,
        days: daysForChart,
        isLineChart,
        chainId,
        tokenAddress,
      });
      return backendResp;
    } else {
      try {
        const { rangeType, aggregate, limit } =
          getGeckoTerminalParams(daysForChart);
        const [geckoChainId, poolAddress, baseOrQuote] = geckoTerminalKey;
        const params = {
          network: geckoChainId,
          pool_address: poolAddress,
          timeframe: rangeType,
          aggregate,
          limit,
          token: baseOrQuote,
        };
        const response = await getGeckoTerminalGraphPriceForTokens({
          params,
        });
        return isLineChart
          ? transformGeckoTerminalForChart(
              response[geckoChainId][poolAddress][rangeType],
              daysForChart
            )
          : transformGeckoTerminalForOHLC(
              response[geckoChainId][poolAddress][rangeType]
            );
      } catch (e) {
        const res = await getCoingeckoStyleGraphPriceForTokens({
          days: daysForChart,
          isLineChart,
          chainId,
          tokenAddress,
        });
        if (res) {
          return { ...res, fallbackResponseFromGeckoTerminal: true };
        }
        return [];
      }
    }
  }
};

const transformGeckoTerminalForChart = (resp, days) => {
  const minTs = tsCutoff(days);
  const data = resp?.data?.attributes?.ohlcv_list;
  if (data == null) {
    return null;
  }

  const valueByTs = {};
  const volumeByTs = {};

  data.reverse().forEach(([ts, o, h, l, c, v]) => {
    if (ts * 1000 >= minTs) {
      valueByTs[ts * 1000] = [o, h, l, c, v];
      volumeByTs[ts * 1000] = v;
    }
  });
  const result = {
    prices: Object.entries(valueByTs).map(([ts, _]) => {
      const [o, h, l, c] = valueByTs[ts];
      return [Number(ts), o, h, l, c];
    }),
    total_volumes: Object.entries(volumeByTs).map(([ts, o]) => [Number(ts), o]),
  };
  return result;
};

export const transformGeckoTerminalForOHLC = (resp, multiply = true) => {
  return resp?.data?.attributes?.ohlcv_list
    ?.reverse()
    .map(([ts, o, h, l, c, v]) => {
      const time = multiply ? ts * 1000 : ts;
      return [time, o, h, l, c, v];
    });
};

const filterCoinGeckoDataForChart = (resp, days) => {
  const minTs = tsCutoff(days);
  const data = produce(resp, (draft) => {
    Object.keys(draft).forEach((key) => {
      draft[key] = draft[key].filter(([ts, _]) => ts >= minTs);
    });
  });
  return data;
};

export const getGeckoTerminalUrl = (
  geckoChainId,
  poolAddress,
  baseOrQuote,
  days
) => {
  const { rangeType, aggregate, limit } = getGeckoTerminalParams(days);
  return `https://api.geckoterminal.com/api/v2/networks/${geckoChainId}/pools/${poolAddress}/ohlcv/${rangeType}?aggregate=${aggregate}&limit=${limit}&currency=usd&token=${baseOrQuote}`;
};

export const getCoingeckoStyleGraphPriceForTokens = async ({
  slug,
  days,
  isLineChart,
  chainId,
  tokenAddress,
}) => {
  const isSolana = chainId?.toLowerCase() === "solana";
  if ((!isSolana && !slug) || (isSolana && !slug && !chainId && !tokenAddress))
    return null;
  let endpoint = "/api/v4/get_coingecko_style_prices_for_tokens";
  const agoS = days === Chart.DURATION_DAYS["ALL"] ? 0 : days * 24 * 60 * 60;
  const tuple =
    chainId && tokenAddress
      ? {
          chain_id_address_or_symbols: [
            {
              chain_id: chainId,
              token_address: tokenAddress,
            },
          ],
        }
      : {};
  const resp = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      coingecko_slugs: slug ? [slug] : [],
      as_ohlc: !isLineChart,
      ago_s: agoS,
      ...tuple,
    }),
  });
  const data = resp.data?.data;
  const returnData = slug ? data?.[slug] ?? [] : data[tokenAddress] ?? [];
  return returnData;
};

export const getGeckoTerminalGraphPriceForTokens = async ({ params }) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_geckoterminal_ohlcv_multi",
    bodyText: JSON.stringify({ items: [params] }),
  });
  return resp.data.data;
};

export const getPriceDelta = async ({ chainId, contractAddress }) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_token_price_delta",
    bodyText: JSON.stringify({
      tokens: [{ chain_id: chainId, token_address: contractAddress }],
      durations: Object.keys(PRICE_DELTA_DURATIONS),
    }),
  });
  const changes = resp.data.data?.[contractAddress];
  if (!changes) return null;
  const priceChanges = Object.keys(changes)
    .filter((key) => PRICE_DELTA_DURATIONS[key])
    .map((key) => {
      const val = changes[key];
      return {
        label: PRICE_DELTA_DURATIONS[key].label,
        value: val.change_percentage?.value ?? 0,
        displayValue: val.change_percentage?.display_value ?? "-",
        direction: val.change_percentage?.direction,
      };
    });
  return {
    priceChanges,
    currentPrice: changes.current_price,
  };
};

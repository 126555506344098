import classes from "./Components.module.css";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import etherScanIcon from "assets/svg/contracts/etherscan.svg";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import { CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Skeleton } from "@mui/material";
import { CaretLeft, CaretDown, CaretUp } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import ConditionalLink from "shared/ConditionalLink";
import Image, { IMAGE_TYPES } from "../Image";
import OverflowText from "../OverflowText";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import whale from "assets/png/whale.png";
import Spinner from "shared/buttonloadingSpinner";
import { InputBase } from "@mui/material";

export const Divider = styled.div`
  ${(props) =>
    ` width:${props.width ?? "100%"};
  border-bottom: 1px ${props.borderStyle ?? "solid"} ${
    props.borderColor ?? "var(--border-dark)"
  } ;
  box-sizing: border-box;
  display: flex;
  opacity:${props.opacity ?? "1"};
  ${props.height != null && `height: ${props.height}`};
  ${props.background != null && `background: ${props.background}`};
  ${props.borderRadius != null && `border-radius: ${props.borderRadius}`};
  ${props.borderColor != null && `border-color: ${props.borderColor}`};
  margin: ${props.margin ?? "0px"};
  `}
`;
export const VerticalDivider = styled.div`
  ${(props) =>
    `  border-left: 1px ${props.borderStyle ?? "solid"} ${
      props.borderColor ?? "var(--border-dark)"
    } ;
  box-sizing: border-box;
  ${props.height != null && `height: ${props.height}`};
  `}
`;

export const InfoBox = styled.div`
  ${(props) =>
    `
    display: flex;
  flex-direction: column;
  ${props.alignItems != null && `align-items: ${props.alignItems}`};
  ${props.justifyContent != null && `justify-content: ${props.justifyContent}`};
  margin: ${props.margin ?? "0px"};
  width: ${props.width ?? "100%"};
  max-width: ${props.maxWidth ?? "auto"};
  height: ${props.height};
  padding:  ${props.padding ?? "16px 20px"};;
  background: ${props.background ?? "var(--base)"};
  border: ${props.borderWidth ?? "1px"} solid ${props.borderColor ?? "var(--border-dark)"};
  border-radius: ${props.radius ?? "4px"};
  font-family: "Roboto";
    `}
`;
export const Container = styled.div`
  ${(props) =>
    ` margin: ${props.margin ?? "0px"};
  width: ${props.width};
  height: ${props.height};
  padding:  ${props.padding ?? "16px 20px"};;
    `}
`;

export const InfoBoxContainer = ({
  header,
  children,
  contentPaddingTop,
  headerPaddingBottom,
  padding = "16px 20px",
  hasHeaderBorder = true,
  headerBackground,
  paddingBottom = "16px",
  ...infoBoxProps
}) => {
  return (
    <>
      <InfoBox {...infoBoxProps} paddingTop="0" padding={padding}>
        {header && (
          <InfoBoxHeader
            contentPaddingTop={contentPaddingTop}
            headerPaddingBottom={headerPaddingBottom}
            hasHeaderBorder={hasHeaderBorder}
            background={headerBackground}>
            {header}
          </InfoBoxHeader>
        )}
        <div className={classes.infoBoxContent}>{children}</div>
      </InfoBox>
      {!!paddingBottom && (
        <PaddingComponent height={paddingBottom}></PaddingComponent>
      )}
    </>
  );
};

export const InfoBoxHeader = ({
  children,
  contentPaddingTop = "16px",
  headerPaddingBottom = "10px",
  hasHeaderBorder = true,
  background = "none",
}) => {
  return (
    <>
      <span className={classes.infoBoxHeader} style={{ background }}>
        {children}
      </span>
      <PaddingComponent height={headerPaddingBottom}></PaddingComponent>
      {hasHeaderBorder && <Divider />}
      <PaddingComponent height={contentPaddingTop}></PaddingComponent>
    </>
  );
};

export const TableHeader = ({ items }) => {
  return (
    <div className={classes.table}>
      <div className={`${classes.tableRow} ${classes.tableHeader}`}>
        {[...items].map((item) => (
          <div
            key={item.name}
            style={{
              flex: item.flex,
              textAlign: item.alignment ?? "left",
            }}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Table = ({ children }) => {
  return <div className={classes.table}>{children}</div>;
};

export const TableRowTile = ({ onClick, link, children }) => {
  if (link) {
    return (
      <div className={`${classes.tableRow} ${classes.tableList}`}>
        <Link to={link} className={classes.tableRow}>
          {children}
        </Link>
      </div>
    );
  }
  return (
    <div className={`${classes.tableRow} ${classes.tableList}`}>
      <div onClick={onClick} className={classes.tableRow}>
        {children}
      </div>
    </div>
  );
};

export const TableRowItem = ({ alignment, flex, children, onClick }) => {
  alignment = alignment || "left";
  return (
    <div
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
        }
      }}
      style={{
        height: "24px",
        flex: flex,
        width: "100%",
        textAlign: alignment,
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}>
      {children}
    </div>
  );
};

export const PaddingComponent = styled.div`
  ${(props) =>
    `
      width : ${props.width};
      height : ${props.height};
      min-height : ${props.minHeight};
      padding-top : ${props.paddingTop};
      padding-bottom: ${props.paddingBottom};
      padding-left: ${props.paddingLeft};
      padding-right: ${props.paddingRight};
      padding: ${props.padding};
      background-color: ${props.backgroundColor};
      border-radius: ${props.borderRadius};
    `}
`;
export const CommonButton = styled.button`
  ${(props) =>
    `
      width : ${props.width};
      height : ${props.height};
      padding: ${props.padding};
      background:${props.background};
      color:${props.color ?? "var(--text-2)"};
      font-size:${props.fontSize ?? "12px"};
      ${!!props.fontWeight ? `font-weight: ${props.fontWeight};` : ""};
      border-radius:${props.borderRadius ?? "4px"};
    `}
`;

export const CustomColumn = styled.div`
  ${(props) =>
    `
      display: flex;
      flex-direction: column;
      width: ${props.width};
      justify-content: ${props.justifyContent};
      align-items: ${props.alignItems};
      gap: ${props.gap};
      background: ${props.background};
      ${props.padding != null && `padding: ${props.padding}`};
      ${props.borderRadius != null && `border-radius: ${props.borderRadius}`};
      ${props.border != null && `border: ${props.border}`};
      ${props.margin != null && `margin: ${props.margin}`};
      ${props.height != null && `height: ${props.height}`};
      ${props.maxWidth != null && `max-width: ${props.maxWidth}`};
      ${props.minWidth != null && `min-width: ${props.minWidth}`};
      ${props.maxHeight != null && `max-height: ${props.maxHeight}`};
      ${props.minHeight != null && `min-height: ${props.minHeight}`};
      ${props.flexShrink != null && `flex-shrink: ${props.flexShrink}`};
      ${props.opacity != null && `opacity: ${props.opacity}`};
      ${props.cursor != null && `cursor: ${props.cursor}`};
      ${props.flexGrow != null && `flex-grow: ${props.flexGrow}`};
      ${props.whiteSpace != null && `white-space: ${props.whiteSpace}`};
      ${props.order != null && `order: ${props.order}`};
      overflow-x:${props.overflowX || "hidden"};
      overflow-y:${props.overflowY || "hidden"};
    `}
`;

export const CustomRow = styled.div`
  ${(props) =>
    `border-radius:${props.borderRadius};
      overflow-x:${props.overflowX || "hidden"};
      overflow-y:${props.overflowY || "hidden"};
      box-sizing: border-box;
      display: flex;
      width: ${props.width};
      ${props.height != null && `height: ${props.height}`};
      ${props.cursor != null && `cursor: ${props.cursor}`};
      background: ${props.background};
      flex-direction: row;
      justify-content: ${props.justifyContent};
      flex-grow: ${props.flexGrow};
      flex-shrink: ${props.flexShrink ?? `auto`};
      ${props.flexGrow != null && `flex-grow: ${props.flexGrow}`};
      align-items: ${props.alignItems};
      gap: ${props.gap};
      flex-wrap: ${props.flexWrap};
      ${props.padding != null && `padding: ${props.padding}`};
      ${props.border != null && `border: ${props.border}`};
      cursor:${props.cursor};
      ${props.flexBasis != null && `flex-basis:${props.flexBasis}`};
      ${props.maxWidth != null && `max-width:${props.maxWidth}`};
      ${props.alignSelf != null && `align-self:${props.alignSelf}`};
      ${props.margin != null && `margin:${props.margin}`};
      ${props.minWidth != null && `min-width:${props.minWidth}`};

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: var(--text-3);
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    `}
`;

export const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const FlexBox = styled.div`
  ${(props) =>
    `
      flex : ${props.flex};
      justify-content: ${props.justifyContent || "flex-end"};
      overflow:${props.overflow || "hidden"};
    `}
`;

export const TypeTag = ({ text, className }) => {
  return (
    <div className={`${classes.typeTag} ${className}`}>
      <span>{text}</span>
    </div>
  );
};

export const IconWrapper = styled.div`
  ${(props) => `
    cursor: ${props.cursor ?? "pointer"};
    onClick: ${props.onClick};
    width: ${props.width ?? "32px"};
    height: ${props.height ?? "32px"};
    box-sizing: border-box;
    border-radius: 50%;
    ${!props.noBorder ? "border: 1px solid var(--border-dark);" : ""};
    align-items: center;
    justify-content: center;
    display: flex;
    background: ${props.color};
  `}
`;

export const ExteralLink = ({ link }) => {
  return (
    <IconWrapper>
      <a href={link} target="_blank" rel="noreferrer">
        <img src={etherScanIcon} alt="twitter-icon" />
      </a>
    </IconWrapper>
  );
};

export const RightPanelCrossIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <CrossIcon className={classes.crossIcon} />
    </div>
  );
};

export const BulletPoint = styled.div`
  ${(props) => `
    width: ${props.width ?? "3px"};
    height: ${props.height ?? "3px"};
    margin: 0px 4px;
    box-sizing: border-box;
    border-radius: 50%;
    background:${props.bgColor ?? "var(--text - 2)"};
  `}
`;

export const CursorDiv = styled.div`
  ${(props) => `
    display: flex;
    align-items: center;
    cursor: pointer;
    width: ${props.width};
  `}
`;

export const CustomCircularProgress = ({ size = 12, isColorWhite = false }) => {
  return (
    <CircularProgress
      className={
        isColorWhite ? classes.circularProgressWhite : classes.circularProgress
      }
      size={size}
    />
  );
};

export const SpinnerComponent = ({ size = 20, color = "var(--text-2)" }) => {
  return (
    <Spinner
      color={color}
      style={{
        stroke: { color },
        background: "transparent",
        width: `${size}px`,
        height: `${size}px`,
        alignSelf: "center",
      }}
    />
  );
};

export const CustomButtonStyle = styled.button`
  ${(props) => `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${props.background ?? "var(--primary-color)"};
  border-radius: ${props.radius ?? "24px"};
  padding: 4px 12px 4px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: ${props.fontWeight ?? "600"}};
  font-size: ${props.fontSize ?? "13px"}};
  line-height: 150%;
  text-align: center;
  height: ${props.height ?? "36px"};
  color: ${props.color ?? "white"};

  &:disabled {
    opacity: 0.5;
  }
  `}
`;

export const CustomButton = ({ text, isLoading, ...props }) => {
  return (
    <CustomButtonStyle {...props} className={props.className}>
      {isLoading ? (
        <SpinnerComponent size={20} color="var(--text-white)" />
      ) : null}
      {text}
    </CustomButtonStyle>
  );
};

export const RedirectLink = ({ link, children, style, ...props }) => {
  return (
    <ConditionalLink
      sameTab={false}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        color: "inherit",
        ...style,
      }}
      to={{ pathname: link }}
      {...props}>
      {children}
    </ConditionalLink>
  );
};

const customTheme = createTheme({
  components: {
    MuiSkeleton: {
      styleOverrides: {
        wave: {
          background:
            "linear-gradient(90deg, var(--shimmer1) 0%, var(--shimmer2) 100%)",
        },
      },
    },
  },
});

export const CustomSkeleton = ({
  width,
  height = 24,
  variant = "text",
  className,
}) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Skeleton
        animation="wave"
        width={width}
        height={height}
        className={className}
        variant={variant}
      />
    </ThemeProvider>
  );
};
export const CustomCheckBox = ({
  isActive,
  isToggleable = true,
  setIsActive,
  children,
  className,
}) => {
  return (
    <label className={`${classes.checkboxWrapper} ${className}`}>
      <input
        className={classes.checkbox}
        type="checkbox"
        disabled={!isToggleable}
        checked={isActive}
        onChange={() => {
          if (!isToggleable) return;
          setIsActive(!isActive);
        }}
      />
      {children}
    </label>
  );
};

export const CustomBackButton = ({ onClick, isShow }) => {
  const history = useHistory();

  if (
    (history.action !== "POP" && history.location.pathname !== "/home") ||
    isShow
  )
    return (
      <CaretLeft
        size={24}
        color="var(--text-1)"
        className={classes.arrow_back_icon}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.goBack();
          }
        }}
      />
    );
  return null;
};

export const LoadingHoverCard = ({ minWidth = 300 }) => {
  return (
    <div
      className={classes.loadingHoverCard}
      style={{
        minWidth,
        display: "flex",
        alignItems: "center",
      }}>
      <CustomCircularProgress size={24} />
    </div>
  );
};

export const MaterialIconWrapper = ({ icon, size, color }) => {
  return (
    <Box
      component={icon}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `${size ?? "24px"}`,
        color: { color },
      }}
    />
  );
};

export const LinkReplace = ({
  to,
  className,
  children,
  onClickTrackingEvent = () => {},
}) => {
  const history = useHistory();
  return (
    <div className={className}>
      <CursorDiv
        onClick={() => {
          onClickTrackingEvent();
          history.replace(to);
        }}>
        {children}
      </CursorDiv>
    </div>
  );
};

export const CustomTextSpan = styled.span`
  ${(props) => `
  font-family: ${props.fontFamily ?? "Roboto"} ;
  white-space: ${props.whitespace};
  font-style: normal;
  font-weight: ${props.fontWeight ?? "400"};
  font-size: ${props.fontSize ?? "15px"};
  line-height: 150%;
  color: ${props.color ?? "var(--text-1)"};
  letter-spacing: ${props.letterSpacing ?? "-0.02em"};
  `}
`;

const multilineOverflow = (lines) => `
  display: -webkit-box;
  -webkit-line-clamp: ${lines}; 
  -webkit-box-orient: vertical;  
  overflow: hidden;
`;

export const CustomTextStyle = styled.span`
  ${(props) => `
  font-family: ${props.fontFamily ?? "Roboto"};
  font-style: normal;
  ${props?.whiteSpace ? `white-space: ${props.whiteSpace};` : ""}
  font-weight: ${props.fontWeight ?? "400"};
  font-size: ${props.fontSize ?? "13px"};
  line-height: ${props.lineHeight ?? "150%"};
  text-align: ${props.textAlign ?? "center"};
  letter-spacing: ${props.letterSpacing ?? "normal"};
  color: ${props.color ?? "var(--text-2)"};
  text-transform: ${props.textTransform ?? "none"};
  border-radius: ${props.borderRadius ?? "0px"};
  background: ${props.background};
  ${props.padding != null ? `padding: ${props.padding};` : ""}
  ${props.width != null ? `width: ${props.width};` : ""}
  ${props.overflow != null ? `overflow: ${props.overflow};` : ""}
  ${props.textOverflow != null ? `text-overflow: ${props.textOverflow};` : ""}
  ${props.maxLines ? multilineOverflow(props.maxLines) : ""}
  ${props.maxWidth ? `max-width: ${props.maxWidth}` : ""}
  `}
`;

export const CustomText = ({ text, ...props }) => {
  return (
    <CustomTextStyle {...props} className={props.className}>
      {text}
    </CustomTextStyle>
  );
};

export const RoundedChipUI = styled.div`
  ${(props) => `
  font-family: Roboto;
  font-style: normal;
  padding: 1px 8px;
  color:  ${props.color ?? "var(--text-2)"};
  font-size: 13px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 19.50px;
  word-wrap: break-word;
  background:${props.bgColor ?? "var(--elevation-1)"};
  border-radius:${props.borderRadius ?? "40px"};
  cursor: pointer;
  border:${props.noBorder ? "none" : "0.50px solid var(--border-dark)"};
  white-space: nowrap;
  `}
`;

export const RoundedChip = ({ children, ...props }) => {
  return <RoundedChipUI {...props}>{children}</RoundedChipUI>;
};

export const TransparentButton = styled.button`
  background: none;
  padding: 0;
`;

export const PriceChange = ({
  value,
  displayValue,
  fontSize = "13px",
  fontWeight = "600",
  showCaret = true,
}) => {
  const isNoChange = value === 0;
  const isPositive = value > 0;

  const color = isNoChange
    ? "var(--text-2)"
    : isPositive
      ? "var(--success)"
      : "var(--error)";

  return (
    <CustomRow alignItems="center" gap="2px">
      {showCaret ? (
        isNoChange || displayValue == null ? (
          "-"
        ) : isPositive ? (
          <CaretUp size={10} color={color} weight="fill" />
        ) : (
          <CaretDown size={10} color={color} weight="fill" />
        )
      ) : null}
      <CustomText
        text={displayValue}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight="16px"
      />
    </CustomRow>
  );
};

export const TrendingTokenPriceInfo = ({
  tokenPrice,
  priceChange,
  isRowLayout = false,
  priceFontSize = "15px",
  priceChangeFontSize = "13px",
}) => {
  const Wrapper = ({ children, ...props }) => {
    if (isRowLayout) {
      return (
        <>
          <PaddingComponent height="12px" />
          <CustomRow gap="8px" alignItems="center" {...props}>
            {children}
          </CustomRow>
          <PaddingComponent height="12px" />
        </>
      );
    }
    return (
      <CustomColumn alignItems="flex-end" {...props}>
        {children}
      </CustomColumn>
    );
  };

  return (
    <Wrapper>
      {!!tokenPrice?.display_value && (
        <CustomText
          text={tokenPrice?.display_value}
          color="var(--text-1)"
          fontSize={priceFontSize}
          fontWeight="600"
          textAlign="right"
        />
      )}
      {!!priceChange?.value && (
        <PriceChange
          value={priceChange?.value}
          displayValue={priceChange?.display_value}
          fontSize={priceChangeFontSize}
        />
      )}
    </Wrapper>
  );
};

export const GradientDivider = styled.div`
  ${({
    startColor = "var(--gradient-divider-base-color-1)",
    endColor = "var(--gradient-divider-base-color-2)",
  }) => `
 width: 100%;
 border-width: 1px;
 border-style: solid;
 border-image: linear-gradient(to right, ${startColor}, ${endColor}) 1 1%;
 border-top: none;
 border-left: none;
 
 `}
`;

export const ConditionalHoverCard = ({ profile, children }) => {
  if (["token", "contract"].includes(profile?.type)) {
    return <TokenHoverCard tokenProfile={profile}>{children}</TokenHoverCard>;
  }
  return <ProfileHoverCard actor={profile}>{children}</ProfileHoverCard>;
};
export const CustomProfileMiniItem = ({
  profile,
  title,
  image,
  subTitle,
  titleOverflow = false,
  subTitleOverflow = false,
  overflowLimit = 55,
  roundAvatar,
  isToken = false,
  isContract = false,
  alignItems,
}) => {
  const isText = (content) => typeof content === "string";

  const conditionalOverflow = (content, condition) =>
    isText(content) && condition ? (
      <OverflowText text={content} limit={overflowLimit} />
    ) : (
      content
    );
  let imageType = IMAGE_TYPES.SMALL_AVATAR;

  if (isToken) {
    imageType = IMAGE_TYPES.SMALL_TOKEN;
  } else if (isContract) {
    imageType = IMAGE_TYPES.SMALL_CONTRACT;
  }
  return (
    <ConditionalHoverCard profile={profile}>
      <div>
        <RedirectLink link={profile?.link}>
          <CustomRow
            gap="12px"
            padding="2px 0"
            {...(!!alignItems ? { alignItems } : {})}>
            <IconWithChain
              src={image}
              imageType={imageType}
              iconHeight="40px"
              iconWidth="40px"
              chainHeight="18px"
              chainWidth="18px"
              chainRightDistance="-6px"
              chain={profile?.chain}
              alt={title}
              showImageFullHeightWidth
            />
            <CustomColumn>
              <CustomText
                text={conditionalOverflow(title, titleOverflow)}
                color="var(--text-1)"
                fontSize="15px"
                fontWeight="600"
                textAlign="left"
              />

              <CustomText
                text={conditionalOverflow(subTitle, subTitleOverflow)}
                fontSize="15px"
                fontWeight="400"
                textAlign="left"
              />
            </CustomColumn>
          </CustomRow>
        </RedirectLink>
      </div>
    </ConditionalHoverCard>
  );
};

export const AdditionalInfoItem = ({
  title,
  value,
  titleFlexGrow,
  valueFlexGrow,
}) => {
  return (
    <>
      <Divider />
      <PaddingComponent height="8px" />
      <BasicInfoRowTile
        title={title}
        value={value}
        titleFlexGrow={titleFlexGrow}
        valueFlexGrow={valueFlexGrow}></BasicInfoRowTile>
      <PaddingComponent height="8px" />
    </>
  );
};

export const CircleAvatar = styled(Image)`
  ${(props) => `
  height: ${props?.height ?? "24px"};
  width: ${props?.width ?? "24px"};
  border: 1px solid var(--base);
  border-radius: ${props?.borderRadius ?? "50%"};
  overflow: hidden;
  ${props.flexShrink != null ? `flex-shrink: ${props.flexShrink};` : ""}
  ${props.objectFit != null ? `object-fit: ${props.objectFit};` : ""}
  `}
`;
export const AlertWrapper = styled(CustomColumn)`
  ${({ background = "var(--error)", borderColor = "var(--error-light)" }) => `
  background: ${background};
  border: 1px solid ${borderColor};
  border-radius: 6px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  `}
`;
export const AlertComponent = ({ type = "error", title, children }) => {
  const alertStyleProps = {
    error: {
      borderColor: "var(--error-lighter)",
      background: "var(--error-lighter)",
      titleColor: "var(--error)",
    },
    success: {
      borderColor: "var(--success-lighter)",
      background: "var(--success-lighter)",
      titleColor: "var(--success)",
    },
  };

  return (
    <AlertWrapper {...alertStyleProps?.[type]}>
      <CustomText
        text={title}
        color={alertStyleProps?.[type]?.titleColor}
        fontSize="12px"
        fontWeight="500"
        textAlign="left"
        textTransform="uppercase"
        letterSpacing="0.96px"
      />
      {children}
    </AlertWrapper>
  );
};

export const DirectedTokenPrice = ({ direction, price, isChevron = false }) => {
  if (!direction || !["bought", "sold"]?.includes(direction) || !price) {
    return null;
  }
  const info = {
    bought: {
      sign: "+",
      color: "var(--success)",
    },
    sold: {
      sign: "-",
      color: "var(--error)",
    },
  };

  return (
    <>
      {isChevron ? (
        direction === "bought" ? (
          <CaretUp size={10} color="var(--success)" weight="fill" />
        ) : (
          <CaretDown size={10} color="var(--error)" weight="fill" />
        )
      ) : null}
      <CustomText
        fontSize="14px"
        fontWeight="500"
        color={info?.[direction]?.color}
        text={`${!isChevron ? info?.[direction]?.sign : ""}${price}`}
      />
    </>
  );
};

export const WhaleIcon = () => <img src={whale} alt="whale icon" />;

export const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "var(--bg)",
    color: "var(--text-1)",
    border: "1px solid var(--border-dark)",
    fontSize: 13,
    padding: "5.5px 6px 5.5px 6px",
    fontFamily: [
      "Roboto",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      border: "1px solid var(--light-primary-color)",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderWidth: "1px",
  },
  "& .MuiSelect-icon": {
    color: "var(--text-2)",
  },
}));
const getColor = (action) => {
  switch (action.toLowerCase()) {
    case "buy":
    case "mint":
    case "transfer":
    case "received":
      return "var(--success)";
    case "sell":
    case "sent":
    case "burn":
      return "var(--error)";
    default:
      return "var(--text-2)";
  }
};
export const BuySellActionText = ({
  action,
  className,
  fontWeight = "500",
}) => {
  return (
    <CustomText
      color={getColor(action)}
      fontSize="13px"
      text={action}
      lineHeight="19.5px"
      className={className}
      fontWeight={fontWeight}
    />
  );
};

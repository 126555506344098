import axios from "axios";
import { signedRequest } from "./api.js";
import {
  getGeckoTerminalGraphPriceForTokens,
  transformGeckoTerminalForOHLC,
} from "components/V2/Token/TokenChart/ChartDataPriceUtils.js";

export const getCoingeckoSlugTokenPrices = async (slugs) => {
  if (slugs == null) return null;
  if (slugs.length === 0) return null;

  // coingecko api
  try {
    const response = await axios(
      `https://api.coingecko.com/api/v3/simple/price?ids=${slugs.join(
        ","
      )}&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true`,
      {
        method: "get",
      }
    );
    return response.data;
  } catch (err) {
    try {
      const response = await getPricesForTokens({ slugIds: slugs, agoS: 1 });
      const transformedResponse = {};
      Object.keys(response || {}).forEach((slug) => {
        transformedResponse[slug] = {
          usd: response[slug]?.[0]?.price,
          last_updated_at: response[slug]?.[0]?.timestamp,
        };
      });
      return transformedResponse;
    } catch (err) {
      throw new Error("Failed to fetch token prices");
    }
  }
};

export const getPricesForTokens = async ({ slugIds, agoS = 30 }) => {
  if (!slugIds?.length) return null;
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_prices_for_tokens",
    bodyText: JSON.stringify({
      coingecko_slugs: slugIds,
      ago_s: agoS,
    }),
  });
  return resp.data?.data?.coingecko_slugs;
};

// slugs of format network_pool
export const getGeckoTerminalTokenPrices = async (slugs) => {
  if (!slugs?.length) return null;

  let networkPools = {};
  slugs.forEach((slug) => {
    const [network, pool] = slug.split("_");
    if (!networkPools[network])
      networkPools[network] = { network, pool_addrs: [] };
    networkPools[network].pool_addrs.push(pool);
  });

  // fetch from geckoterminal only 1 network call can be made at a time
  let fetchPromises = [];
  Object.keys(networkPools).forEach((network) => {
    fetchPromises.push(
      fetch(
        `https://api.geckoterminal.com/api/v2/networks/${network}/pools/multi/${networkPools[
          network
        ].pool_addrs.join(",")}`,
        {
          method: "get",
        }
      )
    );
  });
  try {
    const responses = await Promise.all(fetchPromises);
    let data = [];
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      if (response.status === 200) {
        const current = await response.json();
        data = [...data, ...current?.data];
      }
      if (response.status === 429) {
        // Too many requests, fallback to our api
        throw new Error("Too many requests");
      }
    }
    return data;
  } catch (e) {
    const response = await getGeckoTerminalPools({
      networkPools: Object.values(networkPools),
    });
    return Object.values(response)?.flatMap((p) => p?.data);
  }
};

export const getGeckoTerminalPools = async ({ networkPools }) => {
  if (!networkPools?.length) return null;

  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/geckoterminal_pools_multi",
    bodyText: JSON.stringify({
      network_pools: networkPools,
    }),
  });
  return resp.data?.data;
};

export const getPriceGraphData = async ({
  slug,
  to,
  from,
  resolution,
  chain,
  address,
}) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/get_coingecko_style_prices_for_tokens",
    bodyText: JSON.stringify({
      coingecko_slugs: slug ? [slug] : [],
      as_ohlc: true,
      end_ts: to,
      start_ts: from,
      candle_size_s: resolution,
      chain_id_address_or_symbols: [
        { chain_id: chain, token_address: address },
      ],
    }),
  });
  return res?.data?.data?.[slug] ?? res?.data?.data?.[address] ?? [];
};

export const getGeckoTerminalGraphData = async ({
  terminalKeys,
  resolution,
  limit,
  before,
}) => {
  const [base, network, pool] = terminalKeys;

  const timeframe = resolution.includes("D")
    ? "day"
    : parseInt(resolution) >= 60
      ? "hour"
      : "minute";
  const aggregate = resolution.includes("D") ? 1 : parseInt(resolution) / 60;
  // Keep this here for now
  // const endpoint = `https://api.geckoterminal.com/api/v2/networks/${network}/pools/${pool}/ohlcv/${timeframe}?aggregate=${aggregate}&limit=${limit}&token=${base}&before_timestamp=${before}`;
  const params = {
    base,
    network,
    pool_address: pool,
    timeframe,
    limit,
    aggregate,
    before_timestamp: before,
  };
  const res = await getGeckoTerminalGraphPriceForTokens({ params });

  const prices = transformGeckoTerminalForOHLC(
    res[network][pool][timeframe],
    false
  );
  return prices ?? [];
};

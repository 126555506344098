import classes from "./TokenHoverCard.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  FlexGrow,
  TypeTag,
  Divider,
  BulletPoint,
  RedirectLink,
  LoadingHoverCard,
} from "components/UI/Components/Components";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import useFetchTokenMetaData from "customHooks/useFetchTokenMetaData";
import { ReactComponent as ChevronDownIcon } from "assets/svg/chevron-down.svg";
import { removePlusOrMinusFromPriceChange } from "utils/misc";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { useMemo } from "react";
import { shortStartingAddress } from "utils/misc";

const TokenPrice = ({ tokenData, isNft = false }) => {
  let priceText = tokenData?.price?.display_value;
  if (isNft && priceText != null) priceText = `Trade Value: ${priceText}`;
  return (
    <CustomRow alignItems="center">
      <span className={classes.subtitle}>{priceText}</span>
      <PaddingComponent width="4px" />
      {tokenData?.price_percentage_change?.value && (
        <CustomRow alignItems="center">
          <ChevronDownIcon
            className={
              tokenData?.price_percentage_change?.value >= 0
                ? classes.up_icon
                : classes.down_icon
            }
          />
          <PaddingComponent width="4px" />
          <PaddingComponent paddingTop="2px">
            <span
              className={`${classes.valueChange} ${
                tokenData?.price_percentage_change?.value >= 0
                  ? classes.valueChangeUp
                  : classes.valueChangeDown
              }`}>
              {removePlusOrMinusFromPriceChange(
                tokenData.price_percentage_change?.display_value
              )}
            </span>
          </PaddingComponent>
        </CustomRow>
      )}
    </CustomRow>
  );
};

const TokenHoverCard = ({
  tokenProfile,
  isNativeToken,
  isNft = false,
  nftPrice,
  children,
}) => {
  return (
    <PPLxHoverCard
      content={
        <TokenHoverCardContent
          tokenProfile={tokenProfile}
          isNativeToken={isNativeToken}
          isNft={isNft}
          nftPrice={nftPrice}
        />
      }>
      {children}
    </PPLxHoverCard>
  );
};

const TokenHoverCardContent = ({
  tokenProfile,
  isNativeToken,
  isNft = false,
  nftPrice,
}) => {
  const { data: queryTokenMetadata } = useFetchTokenMetaData(
    tokenProfile?.address,
    tokenProfile?.address_chain,
    !isNft
  );
  const tokenMetadata = useMemo(() => {
    if (queryTokenMetadata == null && isNativeToken) {
      // this was needed for native tokens in feed summar and show till the time token meta data is not fetched
      if (tokenProfile?.token_details) {
        return tokenProfile?.token_details;
      } else {
        // this was needed in case of native token (token transfers in  transactions)
        return tokenProfile;
      }
    } else {
      return queryTokenMetadata;
    }
  }, [queryTokenMetadata, isNativeToken, tokenProfile]);
  const primaryName = isNativeToken
    ? tokenMetadata?.symbol
    : tokenProfile?.token_details?.symbol ?? tokenProfile?.display_name;
  let secondaryName = isNativeToken
    ? tokenMetadata?.name
    : tokenProfile?.display_name;

  // Skip alias if same as primary name
  if (primaryName?.toLowerCase() === secondaryName?.toLowerCase())
    secondaryName = null;

  const hasMarketCap = !isNft && tokenMetadata?.market_cap != null;
  const hasProtocolLink = tokenProfile?.protocol_details?.link != null;

  if (
    tokenMetadata?.symbol == null &&
    isNativeToken &&
    tokenMetadata?.symbol == null
  ) {
    return <LoadingHoverCard />;
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.container}>
      <CustomColumn>
        <CustomRow justifyContent="space-between">
          <RedirectLink
            link={
              isNativeToken ? tokenMetadata?.link : tokenProfile?.address_link
            }
            className={classes.tokenIconWrapper}>
            <IconWithChain
              src={
                isNativeToken
                  ? tokenMetadata?.logo
                  : tokenProfile?.token_details?.image_uri ??
                    tokenProfile?.display_picture
              }
              alt={
                isNativeToken ? tokenMetadata?.name : tokenProfile?.display_name
              }
              chain={tokenProfile?.address_chain}
              imageType={IMAGE_TYPES.TOKEN}
              showImageFullHeightWidth
              iconHeight="48px"
              iconWidth="48px"
              chainHeight="18px"
              chainWidth="18px"
            />
          </RedirectLink>
          <CustomRow gap="8px">
            <ExternalLinks links={tokenProfile?.external_links} />
          </CustomRow>
        </CustomRow>
        <PaddingComponent height="12px" />
        <RedirectLink
          link={
            isNativeToken ? tokenMetadata?.link : tokenProfile?.address_link
          }>
          <div className={classes.title}>{primaryName}</div>
          {secondaryName && (
            <div className={classes.subtitle}>{secondaryName}</div>
          )}
        </RedirectLink>
        <CustomRow>
          <FlexGrow>
            {isNft ? (
              <CustomRow alignItems="center">
                {tokenProfile?.address && (
                  <span className={classes.subtitle}>
                    {shortStartingAddress(tokenProfile?.address)}
                  </span>
                )}
                {nftPrice && tokenProfile?.address && <BulletPoint />}
                <TokenPrice tokenData={{ price: nftPrice }} isNft />
              </CustomRow>
            ) : tokenMetadata ? (
              <TokenPrice tokenData={tokenMetadata} />
            ) : null}
          </FlexGrow>
          <CustomRow justifyContent="flex-end" alignItems="center">
            <TypeTag text={isNft ? "NFT" : "Token"} />
          </CustomRow>
        </CustomRow>
        {(hasProtocolLink || hasMarketCap) && (
          <CustomColumn>
            <PaddingComponent height="12px" />
            <Divider />
            <PaddingComponent height="8px" />
            <CustomRow
              alignItems="center"
              style={{
                flexWrap: "wrap",
              }}>
              {hasMarketCap && (
                <span
                  className={
                    classes.subtitle
                  }>{`M.Cap ${tokenMetadata.market_cap.display_value}`}</span>
              )}
              {hasProtocolLink && (
                <>
                  {hasMarketCap && <BulletPoint />}
                  <span className={classes.subtitle}>Token of</span>
                  <PaddingComponent width="8px" />
                  <RedirectLink link={tokenProfile?.protocol_details?.link}>
                    <Image
                      src={tokenProfile?.protocol_details?.icon}
                      alt={tokenProfile?.protocol_details?.name}
                      imageType={IMAGE_TYPES.CONTRACT}
                      className={classes.tokenImage}
                    />
                  </RedirectLink>
                  <PaddingComponent width="6px" />
                  <RedirectLink link={tokenProfile?.protocol_details?.link}>
                    <span className={classes.subtitle}>
                      {tokenProfile?.protocol_details?.name}
                    </span>
                  </RedirectLink>
                  <CustomRow alignItems="center">
                    <PaddingComponent width="6px" />
                  </CustomRow>
                </>
              )}
            </CustomRow>
          </CustomColumn>
        )}
      </CustomColumn>
    </div>
  );
};

export default TokenHoverCard;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signedRequest } from "api/api";
import { GlobalContext } from "contextStates/Global";
import { produce } from "immer";
import { useContext } from "react";
import { QueryKeyConstants } from "utils/constants";

const paths = {
  like: "create_like",
  unLike: "delete_like",
  bookmark: "create_bookmark",
  unBookmark: "delete_bookmark",
  report: "report_post",
};

export const getEngagementsQueryKey = (type, contentId) => [
  QueryKeyConstants.ENGAGEMENTS,
  contentId + "",
  type,
];

export const useInteractionMutation = ({
  contentId,
  isActive,
  contentType,
  interactionType,
  setIsButtonActive,
  setLikesCount,
  setBookmarksCount,
  queryKey,
  onSuccess,
  oldEmojiId,
  currentIdentityId,
}) => {
  const queryClient = useQueryClient();
  // maintain a list of query keys in order to refetch them if mutating fails

  const interactionMutation = (path) => {
    return async ({ emojiId }) => {
      const response = await signedRequest({
        method: "post",
        path: `/api/0xppl/${path}`,
        bodyText: JSON.stringify({
          id: contentId,
          type: contentType,
          emoji_id: emojiId,
        }),
      });
      return response.data.data;
    };
  };

  const toggleInteraction = (interaction, interactionKey, emojiId) => {
    if (interactionKey === "is_liked") {
      interaction.likes = interaction?.likes.filter(
        (like) => like.creator_identity !== currentIdentityId
      );
      if (emojiId) {
        interaction.likes.push({
          creator_identity: currentIdentityId,
          emoji_id: parseInt(emojiId),
        });
      }
      interaction.count = interaction.likes.length;
      interaction.selected_emoji_id = emojiId;
    }
    if (interactionKey === "is_bookmarked") {
      interaction[interactionKey] = !interaction[interactionKey];
      interaction.count += interaction[interactionKey] ? 1 : -1;
      setBookmarksCount?.(interaction.count);
    }
  };

  const useHandleInteractionMutation = (path) => {
    const { handleErrorSnackbar } = useContext(GlobalContext);

    return useMutation({
      mutationFn: interactionMutation(path),
      onMutate: ({ emojiId }) => {
        // get queries that match the partial query key
        const previousEngagements = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (old) => {
          return produce(old, (draft) => {
            if (["likes", "bookmarks"].includes(interactionType)) {
              const interactionKey =
                interactionType === "likes" ? "is_liked" : "is_bookmarked";
              toggleInteraction(
                draft[interactionType],
                interactionKey,
                emojiId
              );
            }
          });
        });
        return {
          previousEngagements,
        };
      },
      onSuccess: async (data) => {
        if (interactionType === "bookmarks") {
          queryClient.refetchQueries({ queryKey: ["my_bookmark"] });
        } else {
          queryClient.refetchQueries({ queryKey });
        }
        onSuccess?.(data);
      },
      onError: (err, data, context) => {
        handleErrorSnackbar(
          err,
          `Failed to ${interactionType === "likes" ? "like" : "bookmark"}`
        );

        setIsButtonActive?.(!isActive);
        queryClient.setQueryData(queryKey, context.previousEngagements);
      },
    });
  };

  const handleLikeMutation = useHandleInteractionMutation(paths.like);
  const handleUnlikeMutation = useHandleInteractionMutation(paths.unLike);
  const handleBookmarkMutation = useHandleInteractionMutation(paths.bookmark);
  const handleUnBookmarkMutation = useHandleInteractionMutation(
    paths.unBookmark
  );

  const handleInteractionToggle = ({ type, emojiId }) => {
    if (type === "likes") {
      emojiId
        ? handleLikeMutation.mutate({ contentId, emojiId })
        : handleUnlikeMutation.mutate({ contentId });
    } else if (type === "bookmarks") {
      isActive
        ? handleBookmarkMutation.mutate({ contentId })
        : handleUnBookmarkMutation.mutate({ contentId });
    }
  };

  return {
    handleInteractionToggle,
  };
};

export const handlePostToFeedOptimisticUpdates = ({
  queryClient,
  queryKey,
  newData,
}) => {
  queryClient.setQueryData(queryKey, (oldData) =>
    produce(oldData, (draftState) => {
      if (oldData?.pages?.[0]?.data) {
        draftState.pages[0].data = [newData, ...draftState.pages[0].data];
      }
    })
  );
};
export const handleRepostActivityGroupOptimisticUpdates = ({
  queryClient,
  queryKey,
  newData,
}) => {
  queryClient.setQueryData(queryKey, (oldData) =>
    produce(oldData, (draftState) => {
      draftState.engagements.reposts.threads = [
        newData,
        ...(oldData.engagements.reposts.threads || []),
      ];
      draftState.engagements.reposts.count++;
    })
  );
};
export const handleRepostToFeedOptimisticUpdates = ({
  queryClient,
  queryKey,
  newData,
}) => {
  handlePostToFeedOptimisticUpdates({
    queryClient,
    queryKey,
    newData,
  });
};

const commentUpdate = (oldData, newData) =>
  produce(oldData, (draftState) => {
    draftState = { ...oldData };
    draftState.engagements = { ...oldData?.engagements };
    draftState.engagements.replies = { ...oldData?.engagements?.replies };
    draftState.engagements.replies.threads = [
      ...(oldData?.engagements?.replies?.threads || []),
      newData,
    ];
    draftState.engagements.replies.involved_identity_ids = [
      newData?.actor?.id,
      ...(oldData?.engagements?.replies?.involved_identity_ids || []),
    ];
    draftState.identities = {
      ...oldData?.identities,
      ...newData.identities,
    };
    draftState.engagements.replies.count =
      (oldData?.engagements?.replies?.count || 0) + 1;

    draftState.post = { ...oldData?.post };
    const oldProfiles = oldData?.profiles ?? {};
    const newProfiles = newData?.profiles ?? {};
    draftState.profiles = { ...oldProfiles, ...newProfiles };
    draftState.post.engagements = { ...oldData?.engagements };
    draftState.post.engagements.replies = {
      ...oldData?.engagements?.replies,
    };
    draftState.post.engagements.replies.threads = [
      ...(oldData?.engagements?.replies?.threads || []),
      newData,
    ];
    draftState.post.engagements.replies.involved_identity_ids = [
      newData?.actor?.id,
      ...(oldData?.engagements?.replies?.involved_identity_ids || []),
    ];
    draftState.post.identities = {
      ...oldData?.identities,
      ...newData.identities,
    };
    draftState.post.engagements.replies.count =
      (oldData?.engagements?.replies?.count || 0) + 1;
    return draftState;
  });
export const handleCommentOptimisticUpdates = ({
  queryClient,
  queryKey,
  newData,
}) => {
  queryClient.setQueryData(queryKey, (oldData) => {
    const parentPostId = parseInt(newData?.ancestors?.[0]?.id);
    if (parentPostId === parseInt(oldData?.id)) {
      const combined = commentUpdate(oldData, newData);
      return combined;
    } else if (parentPostId === parseInt(oldData?.ancestors?.[0]?.id)) {
      // update ancestor
      const newAncestor = commentUpdate(oldData?.ancestors?.[0], newData);
      const newPost = { ...oldData, ...newData, ancestors: [newAncestor] };
      return newPost;
    }
    return oldData;
  });
};

export const getQueryKeysWithPartialKeys = ({ queryKeys, queryClient }) => {
  if (!queryClient || !queryKeys) {
    return [];
  }

  let returnedQueryKeys = [];

  queryKeys.forEach((queryKey) => {
    const data = queryClient.getQueriesData({ queryKey });
    returnedQueryKeys.push(...data.map((item) => item?.[0]));
  });

  return returnedQueryKeys;
};

import classes from "./ExternalLinks.module.css";
import etherScanIcon from "assets/svg/contracts/etherscan.svg";
import openSea from "assets/svg/opensea_external_link.svg";
import looksRare from "assets/svg/looksrare_external_link.svg";
import blur from "assets/svg/blur_external_link.svg";
import x2y2 from "assets/svg/x2y2_external_link.svg";
import webSiteIcon from "assets/svg/contracts/website.svg";
import tenderlyIcon from "assets/png/tenderlyIcon.png";
import dexscreenerIcon from "assets/svg/dexscreener.svg";
import birdeyeIcon from "assets/svg/birdeye.svg";
import { CustomRow, IconWrapper } from "components/UI/Components/Components";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import TelegramIcon from "@mui/icons-material/Telegram";
import { farcasterIcon } from "utils/assets";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { RedirectLink } from "components/UI/Components/Components";

const ExternalLinks = ({ links, isVerticalDivider = false }) => {
  const iconStyling = {
    height: "18px",
    width: "18px",
    fontSize: "18px",
    color: "var(--text-2)",
  };
  return (
    <div className={classes.socials}>
      {links?.opensea && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.opensea}>
            <img
              className={classes.imageIcon}
              src={openSea}
              alt="opensea-icon"
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.looksrare && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.looksrare}>
            <img
              className={classes.imageIcon}
              src={looksRare}
              alt="looksrare-icon"
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.blur && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.blur}>
            <img className={classes.imageIcon} src={blur} alt="blur-icon" />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.x2y2 && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.x2y2}>
            <img className={classes.imageIcon} src={x2y2} alt="x2y2-icon" />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.twitter && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.twitter}>
            <CustomRow>
              <TwitterIcon sx={iconStyling} />
            </CustomRow>
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.lens && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.lens}>
            <img
              className={classes.imageIcon}
              src={"/lens_external_link.png"}
              alt="lens-icon"
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.farcaster && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.farcaster}>
            <img
              className={classes.imageIcon}
              src={farcasterIcon}
              alt="farcaster-icon"
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.github && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.github}>
            <CustomRow>
              <GitHubIcon sx={iconStyling} />
            </CustomRow>
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.telegram && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.telegram}>
            <CustomRow>
              <TelegramIcon sx={iconStyling} />
            </CustomRow>
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.linkedin && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.linkedin}>
            <CustomRow>
              <LinkedInIcon sx={iconStyling} />
            </CustomRow>
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.tenderly_url && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.tenderly_url}>
            <img
              src={tenderlyIcon}
              alt="website-icon"
              className={classes.imageIcon}
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {(links?.explorer_link || links?.explorer_url) && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.explorer_link || links?.explorer_url}>
            <img
              className={classes.imageIcon}
              src={etherScanIcon}
              alt="explorer-icon"
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {(links?.external_link || links?.website) && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.external_link ?? links?.website}>
            <img
              src={webSiteIcon}
              alt="website-icon"
              className={classes.imageIcon}
            />
          </RedirectLink>
        </IconWrapper>
      )}

      {links?.dexscreener && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.dexscreener}>
            <img
              src={dexscreenerIcon}
              alt="website-icon"
              className={classes.imageIcon}
            />
          </RedirectLink>
        </IconWrapper>
      )}
      {links?.birdeye && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <RedirectLink link={links?.birdeye}>
            <img
              src={birdeyeIcon}
              alt="website-icon"
              className={classes.imageIcon}
            />
          </RedirectLink>
        </IconWrapper>
      )}

      {isVerticalDivider && <div className={classes.verticalDivider}></div>}
    </div>
  );
};

export default ExternalLinks;

import { signedApiRequest, signedRequest } from "./api";

export const getOnboardingData = async ({
  identifier,
  invite_code = null,
  withError = false,
}) => {
  const body = JSON.stringify({
    invite_code,
    can_connect_multiple_socials: true,
  });
  if (!withError) {
    return signedApiRequest({
      method: "post",
      path: "onboarding_interaction",
      bodyText: body,
    });
  } else {
    try {
      const data = await signedApiRequest({
        method: "post",
        path: "onboarding_interaction",
        bodyText: body,
      });
      return data;
    } catch (err) {
      return {
        is_error: true,
        ...err,
      };
    }
  }
};

export const getOnboardingStepDetails = async () => {
  return signedApiRequest({
    method: "post",
    path: "get_onboarding_step_details",
  });
};

export const getOnboardingWalletChecklist = async () => {
  return signedApiRequest({
    method: "post",
    path: "onboarding_wallet_checklist",
  });
};

export const getOnboardingNetWorth = async ({ identifier }) => {
  const res = await signedApiRequest({
    method: "post",
    path: "get_updated_net_worth",
    bodyText: JSON.stringify({
      identifier,
    }),
  });
  const networth = res.data.data[identifier];
  return networth;
};

export const joinWaitlist = async ({ name, email, twitter, linkedIn } = {}) => {
  return signedApiRequest({
    method: "post",
    path: "waitlist_interaction",
    bodyText: JSON.stringify({
      name: name,
      email: email,
      twitter: twitter,
      linkedIn: linkedIn,
    }),
  });
};

export const authorizeWithInviteCode = async ({ inviteCode }) => {
  return signedApiRequest({
    method: "post",
    path: "authorize_with_invite_code",
    bodyText: JSON.stringify({
      invite_code: inviteCode,
    }),
  });
};

export const changeWallet = async ({ nonce } = {}) => {
  return signedApiRequest({
    method: "post",
    path: "waitlist_interaction",
    bodyText: JSON.stringify({
      nonce: nonce,
    }),
  });
};

export const moveOnboardingState = async ({ currentStep }) => {
  const res = signedApiRequest({
    method: "post",
    path: "move_to_next_onboarding_state",
    bodyText: JSON.stringify({
      current_state: currentStep,
    }),
  });
  return res;
};

export const isValidInviteCode = async ({ inviteCode }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/access_control/is_invite_code_valid",
    bodyText: JSON.stringify({
      code: inviteCode,
    }),
  });
  return res.data.data;
};

export const getLensConnectChallenge = async ({ address }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/lens/request_login",
    bodyText: JSON.stringify({
      address,
    }),
  });
  return res.data.data.challenge;
};

export const lensCallback = async ({ address, signature }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/lens/lens_callback",
    bodyText: JSON.stringify({
      address,
      signature,
    }),
  });
  return res.data.data;
};

import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  clearAllQueryCache,
  isAllAuthValidKeyAvailable,
  connectWallet,
  navigateUserPath,
  routeFromWaitlistResp,
  signInSolanaUtil,
} from "./auth_utils";
import { getIdentity, verifyWalletApi } from "api/profile.api";
import { tracker } from "../index";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { GlobalContext } from "contextStates/Global";
import { getOnboardingData } from "api/waitlist.api";
import { initialiseEventTracking, trackEvent } from "../utils/event_tracking";

export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
  const identityDetails = useRef(null);
  const sessionDetails = useRef(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const isLoginPopUpVisible = useRef(false);
  const showLoginPopUpUpdateFunction = useRef(() => {});

  const setSessionDetails = useCallback((data) => {
    if (data === null) return;
    sessionDetails.current = data;
  }, []);

  //whenever pop up show value is changed will be changed through this function
  //where we will store whether pop up is shown or not
  const setShowLoginPopup = useCallback((showPopUp) => {
    isLoginPopUpVisible.current = showPopUp;
    showLoginPopUpUpdateFunction.current(showPopUp);
  }, []);

  const showLoginPopup = useCallback(() => {
    return isLoginPopUpVisible.current;
  }, []);

  const setIdentityDetails = useCallback((details) => {
    identityDetails.current = details;
    toggleIntercomWidget(details);
  }, []);

  //this function is used to store setState function of showLoginPopup in LoginUp
  const updateSetShowLoginPopup = useCallback((setStateFunction) => {
    showLoginPopUpUpdateFunction.current = setStateFunction;
  }, []);

  const setUserIdentityDetails = useCallback((data) => {
    if (data === null) return;
    identityDetails.current = data;
    if (data.identity != null && data.identity?.id != null) {
      tracker?.setUserID(`${data?.identity?.id} (${data?.identity?.title})`);
    }
    toggleIntercomWidget(data);
  }, []);

  const navigateUser = useCallback(() => {
    const path = navigateUserPath({
      identityData: identityDetails.current,
    });
    return path;
  }, []);

  const logout = useCallback(
    ({ isNavigating = true } = {}) => {
      localStorage.clear();
      sessionStorage.clear();
      sessionDetails.current = null;
      setIsUserLoggedIn(false);
      setIdentityDetails(null);
      if (isNavigating) {
        history.push("/onboarding/intro");
      }
      clearAllQueryCache(queryClient);
      toggleIntercomWidget(null);
    },
    [history, queryClient, setIdentityDetails]
  );

  const isUserAuthenticated = useCallback(
    async ({
      isNavigating = false,
      forceWaitlist = false,
      navigateCallback,
      returnData = false,
    } = {}) => {
      const route = window?.location?.pathname;
      const isOnboardingRoute = route.includes("/onboarding");
      let desirableRootPath = "/";
      if (!isAllAuthValidKeyAvailable()) {
        setIsUserLoggedIn(false);
        return desirableRootPath;
      }

      try {
        const identityData = await getIdentity();
        setUserIdentityDetails(identityData?.data?.data);
        if (identityData?.data?.data?.identity) {
          initialiseEventTracking({
            identityDetails: identityData?.data?.data,
          });
        }
        // If user onboarding is pending
        if (identityData.data.data.pending_onboarding) {
          if (isOnboardingRoute) {
            const inviteCode = localStorage.getItem("inviteCode");
            let res = await getOnboardingData({
              identifier: null,
              ...(inviteCode && { invite_code: inviteCode }), // Include inviteCode if exists
            });
            identityData?.data?.data?.onboarding_state?.current ===
              "complete_profile" && localStorage.removeItem("inviteCode"); // Remove inviteCode after successful call
            desirableRootPath = routeFromWaitlistResp({ data: res?.data });
          } else {
            desirableRootPath = "/onboarding/intro?redirect=true";
          }
        } else {
          setIsUserLoggedIn(true);
          if (showLoginPopup()) {
            setShowLoginPopup(false);
          }
          desirableRootPath = navigateUser();
        }
      } catch (err) {
        initialiseEventTracking({ identityDetails: null });
        setIsUserLoggedIn(false);
        const status = err?.response?.status;
        if (status === 404 && (forceWaitlist || isNavigating)) {
          trackEvent("user_not_logged_in");
          const inviteCode = localStorage.getItem("inviteCode");
          let res;
          try {
            res = await getOnboardingData({
              identifier: null,
              ...(inviteCode && { invite_code: inviteCode }), // Include inviteCode if exists
            });
            localStorage.removeItem("inviteCode"); // Remove inviteCode after successful call
            desirableRootPath = routeFromWaitlistResp({ data: res?.data });
          } catch (err) {
            handleErrorSnackbar(null, "Oops! Something went wrong!");
            if (status === 500) {
              desirableRootPath = "/onboarding/intro";
            } else desirableRootPath = null;
            // ^ dont move the user's route here
          }
        } else {
          desirableRootPath = "/onboarding/intro";
        }
      }
      if (isNavigating) {
        if (navigateCallback != null) {
          navigateCallback?.(desirableRootPath);
        } else {
          history.push(desirableRootPath);
        }
      }

      return desirableRootPath;
    },
    [
      navigateUser,
      setUserIdentityDetails,
      setShowLoginPopup,
      showLoginPopup,
      history,
      handleErrorSnackbar,
    ]
  );

  const verifySolanaWallet = async ({
    address,
    onConnect,
    onSignSuccess,
    onFlowComplete,
    connect,
    connected,
    signMessage,
    publicKey,
    onSuccess,
  }) => {
    return signInSolanaUtil({
      isLocalStorageChange: false,
      onConnect,
      onSignSuccess,
      connect,
      connected,
      signMessage,
      publicKey,
      onFlowComplete,
    })
      .then(async (session) => {
        return verifyWalletWithApi({
          session: session,
          onSuccess: onSuccess,
          walletType: "SOLANA",
        });
      })
      .catch((err) => {
        handleErrorSnackbar("something went wrong", err?.message);
        return;
      });
  };

  const verifyWalletWithApi = async ({ session, onSuccess, walletType }) => {
    return verifyWalletApi({
      signature: session.tokenSignature,
      tokenSerialized: session.tokenSerialized,
      walletType: walletType,
    })
      .then((resp) => {
        handleSuccessSnackbar("Wallet verified successfully!");
        onSuccess?.();
        return true;
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          handleErrorSnackbar(
            null,
            err.response?.data?.errors?.errors || "Oops! Something went wrong."
          );
          return;
        }
        handleErrorSnackbar(
          null,
          "Oops! Something went wrong. Please try again!"
        );
        return;
      });
  };

  const verifyWallet = async ({ address, session, onSuccess }) => {
    if (address === session.userAddress) {
      await verifyWalletWithApi({
        session: session,
        walletType: session.walletType,
      });
      onSuccess?.();
      handleSuccessSnackbar("Wallet verified successfully!");
      return;
    } else {
      handleErrorSnackbar(
        null,
        "Oops! Seems like you have verifed with different wallet. Please verify with same wallet to continue."
      );
      return;
    }
  };

  const signInForWaitlist = useCallback(
    async ({ onConnect, onSignSuccess, onFlowComplete }) => {
      try {
        const session = await connectWallet({ onConnect, onSignSuccess });
        clearAllQueryCache(queryClient);
        setSessionDetails(session);
        onFlowComplete?.({ success: true });
      } catch (err) {
        handleErrorSnackbar(null, err.message);
        onFlowComplete?.({ success: false, err });
      }
    },
    [queryClient, setSessionDetails, handleErrorSnackbar]
  );

  const signIn = useCallback(
    async ({ onConnect, onSignSuccess, onFlowComplete }) => {
      connectWallet({ onConnect, onSignSuccess })
        .then((session) => {
          clearAllQueryCache(queryClient);
          setSessionDetails(session);
          isUserAuthenticated({ isNavigating: true });
          onFlowComplete?.({ success: true });
        })
        .catch((err) => {
          handleErrorSnackbar(null, err.message);
          onFlowComplete?.({ success: false, err });
        });
    },
    [isUserAuthenticated, queryClient, handleErrorSnackbar, setSessionDetails]
  );

  const signInWithSolana = useCallback(
    async ({
      onConnect,
      onSignSuccess,
      onFlowComplete,
      connect,
      connected,
      signMessage,
      publicKey,
      isLocalStorageChange = true,
      isMarkingUserAuthenticated = true,
    }) => {
      signInSolanaUtil({
        onConnect,
        onSignSuccess,
        connect,
        connected,
        signMessage,
        publicKey,
        isLocalStorageChange,
      })
        .then((session) => {
          clearAllQueryCache(queryClient);
          setSessionDetails(session);
          if (isMarkingUserAuthenticated) {
            isUserAuthenticated({ isNavigating: true });
          }
          onFlowComplete?.({ success: true });
        })
        .catch((err) => {
          handleErrorSnackbar(null, err.message);
          onFlowComplete?.({ success: false, err });
        });
    },
    [isUserAuthenticated, queryClient, handleErrorSnackbar, setSessionDetails]
  );

  const getUserAddress = useCallback(() => {
    return localStorage.getItem("userAddress");
  }, []);

  const isSuperuser = identityDetails?.current?.superuser ?? false;

  const toggleIntercomWidget = (identityData) => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher:
          identityData?.onboarding_state?.current === "complete",
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        setIdentityDetails,
        showLoginPopup,
        setShowLoginPopup,
        updateSetShowLoginPopup,
        isUserLoggedIn,
        identityDetails,
        isUserAuthenticated,
        signIn,
        logout,
        navigateUser,
        getUserAddress,
        signInForWaitlist,
        isSuperuser,
        verifyWallet,
        signInWithSolana,
        verifySolanaWallet,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import classes from "./GenericFallBackUI.module.css";

const GenericFallBackUI = ({ containerClass, fullPage = false }) => {
  let style = {};
  if (fullPage) {
    style = {
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  return (
    <div className={`${containerClass} ${classes.container}`} style={style}>
      <div className={classes.header}>-(•...•)-</div>
      <div className={classes.text}>There seems to be an issue.</div>
    </div>
  );
};

export default GenericFallBackUI;
